<template>
    <div class="total">
        <vue-apex-charts v-if="total>0" type="pie"
                         height="140"
                         class="mt-0 mb-0"
                         :options="options"
                         :series="series" />
        <!--<div v-else class="chart-pie">0</div>-->
        <div v-else class="mt-0 mb-0" style="min-height: 122.8px;">
            <div  class="apexcharts-canvas apexchartsqod5vpf4h apexcharts-theme-light" style="width: 300px; height: 122.8px;">
                <svg width="300" height="122.80000000000001" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" class="apexcharts-svg" xmlns:data="ApexChartsNS" transform="translate(0, 0)" style="background: transparent;">
                    <g id="SvgjsG1051" class="apexcharts-inner apexcharts-graphical" transform="translate(93, 0)">
                        <defs id="SvgjsDefs1050">
                            <clipPath id="gridRectMaskqod5vpf4h">
                                <rect id="SvgjsRect1053" width="120" height="138" x="-2" y="0" rx="0" ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fff"></rect>
                            </clipPath>
                            <clipPath id="gridRectMarkerMaskqod5vpf4h">
                                <rect id="SvgjsRect1054" width="120" height="142" x="-2" y="-2" rx="0" ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fff"></rect>
                            </clipPath>
                            <filter id="SvgjsFilter1062" filterUnits="userSpaceOnUse" width="200%" height="200%" x="-50%" y="-50%">
                                <feFlood id="SvgjsFeFlood1063" flood-color="#000000" flood-opacity="0.45" result="SvgjsFeFlood1063Out" in="SourceGraphic"></feFlood>
                                <feComposite id="SvgjsFeComposite1064" in="SvgjsFeFlood1063Out" in2="SourceAlpha" operator="in" result="SvgjsFeComposite1064Out"></feComposite>
                                <feOffset id="SvgjsFeOffset1065" dx="1" dy="1" result="SvgjsFeOffset1065Out" in="SvgjsFeComposite1064Out"></feOffset>
                                <feGaussianBlur id="SvgjsFeGaussianBlur1066" stdDeviation="1 " result="SvgjsFeGaussianBlur1066Out" in="SvgjsFeOffset1065Out"></feGaussianBlur>
                                <feMerge id="SvgjsFeMerge1067" result="SvgjsFeMerge1067Out" in="SourceGraphic"><feMergeNode id="SvgjsFeMergeNode1068" in="SvgjsFeGaussianBlur1066Out"></feMergeNode>
                                    <feMergeNode id="SvgjsFeMergeNode1069" in="[object Arguments]"></feMergeNode>
                                </feMerge>
                                <feBlend id="SvgjsFeBlend1070" in="SourceGraphic" in2="SvgjsFeMerge1067Out" mode="normal" result="SvgjsFeBlend1070Out"></feBlend>
                            </filter>
                            <filter id="SvgjsFilter1081" filterUnits="userSpaceOnUse" width="200%" height="200%" x="-50%" y="-50%">
                                <feComponentTransfer id="SvgjsFeComponentTransfer1082" result="SvgjsFeComponentTransfer1082Out" in="SourceGraphic">
                                    <feFuncR id="SvgjsFeFuncR1083" type="linear" slope="0.5"></feFuncR>
                                    <feFuncG id="SvgjsFeFuncG1084" type="linear" slope="0.5"></feFuncG>
                                    <feFuncB id="SvgjsFeFuncB1085" type="linear" slope="0.5"></feFuncB>
                                    <feFuncA id="SvgjsFeFuncA1086" type="identity"></feFuncA>
                                </feComponentTransfer>
                            </filter>
                        </defs>
                        <g id="SvgjsG1055" class="apexcharts-pie">
                            <g id="SvgjsG1056" transform="translate(0, 0) scale(1)">
                                <g id="SvgjsG1057" class="apexcharts-slices">
                                    <g id="SvgjsG1058" class="apexcharts-series apexcharts-pie-series" seriesName="seriesx1" rel="1" data:realIndex="0">
                                        <path id="SvgjsPath1059" d="M 58 5.414634146341456 A 52.585365853658544 52.585365853658544 0 1 1 57.99082212232147 5.414634947262371 L 58 58 L 58 5.414634146341456" fill="rgba(115,103,240,1)" fill-opacity="1" stroke-opacity="1" stroke-linecap="butt" stroke-width="0" stroke-dasharray="0" class="apexcharts-pie-area apexcharts-pie-slice-0" index="0" j="0" data:angle="360" data:startAngle="0" data:strokeWidth="0"
                                              data:value="0"
                                              data:pathOrig="M 58 5.414634146341456 A 52.585365853658544 52.585365853658544 0 1 1 57.99082212232147 5.414634947262371 L 58 58 L 58 5.414634146341456" selected="true" filter="url(#SvgjsFilter1081)"></path></g>
                                    <g id="SvgjsG1060" class="apexcharts-datalabels"><text id="SvgjsText1061" font-family="Helvetica, Arial, sans-serif" x="58" y="73.06829268292684" text-anchor="middle" dominant-baseline="auto" font-size="30px" font-weight="400" fill="#ffffff" class="apexcharts-text apexcharts-pie-label" filter="url(#SvgjsFilter1062)" style="font-family: Helvetica, Arial, sans-serif;">0</text></g></g></g></g><line id="SvgjsLine1071" x1="0" y1="0" x2="116" y2="0" stroke="#b6b6b6" stroke-dasharray="0" stroke-width="1" class="apexcharts-ycrosshairs"></line><line id="SvgjsLine1072" x1="0" y1="0" x2="116" y2="0" stroke-dasharray="0" stroke-width="0" class="apexcharts-ycrosshairs-hidden"></line></g><g id="SvgjsG1052" class="apexcharts-annotations"></g></svg><div class="apexcharts-legend"></div><div class="apexcharts-tooltip apexcharts-theme-dark" style="left: 134.5px; top: 6.8px;"><div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex; background-color: rgb(115, 103, 240);"><span class="apexcharts-tooltip-marker" style="background-color: rgb(115, 103, 240); display: none;"></span><div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">series-1: </span><span class="apexcharts-tooltip-text-value">3</span></div><div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span>
            </div></div></div></div></div></div>
        <p style="font-size: 12px;">{{title}}</p>
    </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts'
  export default {
    components: {
      VueApexCharts,
    },
    props: {
      title:{
        type: String,
        default: ''
      },
      total: {
        type: Number,
        default: 0
      },
      labels: {
        type: Array,
        default() {
          return  ['风险总数']
        }
      },
      colors: {
        type:Array,
        default() {
          return  ['#ea5455', '#ff9f43', '#28c711']
        }
      }
    },
    data() {
      return {
        options:{
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false
          },
          // labels: ['风险总数'],
          labels: [],
          dataLabels: {
            enabled: true,
            formatter: function (value, { w }) {
              return w.config.series[0]
            },
            style: {
              fontSize: '30px',
              fontWeight: '400',
            },
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -27
              }
            }
          },
          stroke: {
            width: 0,
          },
          colors: this.colors,
        },
        // series: [55],
        series: [],
      }
    },
    created() {
      this.series.push(this.total)
      this.options.labels = this.labels
    }
  }
</script>

<style scoped>
    .total {
        text-align: center;
        font-size: 14px;
    }
    .chart-pie{
        margin-bottom: 10px;
        width: 106px;
        height: 106px;
        background: #7367f0;
        font-size: 30px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        border-radius: 50%;
    }
</style>