<template>
  <el-tabs
    v-model="activeName"
    type="border-card"
  >
    <el-tab-pane
      v-for="(item,index) in navMenus"
      :label="item.label"
      :lazy="true"
      :name="String(index+1)"
    >
      <DatashareAtlas v-if="item.label === '数据共享视图'" />
      <DataShareList v-if="item.label === '共享数据管理'" />
    </el-tab-pane>
    <!--<el-tab-pane-->
    <!--label="数据共享视图"-->
    <!--:lazy="true"-->
    <!--name="1"-->
    <!--&gt;-->
    <!--<DatashareAtlas v-if="activeName==1" />-->
    <!--</el-tab-pane>-->
    <!--<el-tab-pane-->
    <!--label="共享数据管理"-->
    <!--:lazy="true"-->
    <!--name="2"-->
    <!--&gt;-->
    <!--<DataShareList v-if="activeName==2" />-->
    <!--</el-tab-pane>-->
  </el-tabs>
</template>

<script>
import DatashareAtlas from './DatashareAtlas.vue'
import DataShareList from './DataShareList.vue'

export default {
  name: 'DataShare',
  components: { DataShareList, DatashareAtlas },
  data() {
    return {
      activeName: '1',
      navMenus: [],
      fullMenus: ['数据共享视图', '共享数据管理'],
    }
  },
  watch: {
    activeName() {
      sessionStorage.setItem('DataShare', this.activeName)
    },
  },
  created() {
    const twoLevelMenus = localStorage.getItem('twoLevelMenus')
    if (twoLevelMenus) {
      const menus = JSON.parse(twoLevelMenus)
      menus.forEach(d => {
        if (this.fullMenus.indexOf(d.name) !== -1) {
          this.navMenus.push({
            label: d.name,
          })
        }
      })
    }
    this.activeName = sessionStorage.getItem('DataShare') || '1'
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.setItem('DataShare', '1')
    next()
  },
}
</script>

<style scoped>

</style>
