import {
  get, post, downloadByGet, Delete,
} from '@/libs/axios'

export const listAllType = () => get('/dataMap/ListAllType')
export const listCollectRecord = params => post('/dataMap/ListCollectRecord', params)
export const GetDataMappingById = params => post('/dataMap/dataMapping/GetDataMappingById', params)
export const DeleteAssetsPrivacyFieldByIds = params => post('/assets/data/delete', params)
export const GetDataAcquisitionListByPage = params => post('/data/mapping/page', params)

export const GetAcquisitionClientOptionsList = params => post('/dataMap/dataMapping/GetAcquisitionClientOptionsList', params)

export const GetBusinessOptions = params => post('/dataMap/dataMapping/GetBusinessOptions', params)

// 删除数据采集
export const saveOrUpdateDataMapping = params => {
  const submitUrl = params.id ? '/data/mapping/update' : '/data/mapping/create'
  return post(submitUrl, params)
}
//
export const mappingInfo = id => get(`/data/mapping/info?id=${id}`)

export const DeleteDataMapping = params => post('/dataMap/dataMapping/DeleteDataMapping', params)
export const GetPrivateSankeyChart = params => post('/privacy/graph/GetPrivateSankeyChart', params)
export const GetPrivateFlowChart = params => post('/privacy/graph/GetPrivateFlowChart', params)
export const SaveNodeLocation = params => post('/privacy/graph/SaveNodeLocation', params)
export const GetLineInfo = value => get(`/privacy/graph/GetLineInfo?value=${value}`)
export const GetNodeInfo = value => get(`/privacy/graph/GetNodeInfo?value=${value}`)
export const GetDataMappingListByDataAcquisitionId = params => post('/dataMap/dataMapping/GetDataMappingListByDataAcquisitionId', params)
export const FinishDataMapping = params => post('/data/mapping/finish', params)
export const queryExamPaper = typeId => get(`/dataMap/QueryExamPaper?typeId=${typeId}`)
export const GetEnterpriseAssetsPrivacyFieldFlowList = typeId => get('enterprise/assets/GetEnterpriseAssetsPrivacyFieldFlowList')
export const GetPrivacyFieldChart = () => get('/dataMap/assetsPrivacyField/GetPrivacyFieldChart')
// 获取保存数据库名列表作为筛选条件
export const GetAllDatabaseName = () => get('/dataMap/assetsPrivacyField/GetAllDatabaseName')
export const GetAllTableName = () => get('/dataMap/assetsPrivacyField/GetAllTableName')
// 数据来源字段选择下拉
export const GetSourceFieldOptionsList = params => post('/dataMap/dataMapping/GetSourceFieldOptionsList', params)

// 个人数据资产列表
export const GetAssetsPrivacyFieldListByPage = params => post('/assets/data/page', params)
// 更新个人隐私字段数据量
export const UpdateSaveCount = params => post('/assets/data/update/save_count', params)

// 添加或编辑个人数据资产
export const saveOrUpdateAssetsPrivacyField = params => {
  const submitUrl = params.id ? '/assets/data/update' : '/assets/data/create'
  return post(submitUrl, params)
}

export const getAssetsDataInfo = id => get(`/assets/data/info?id=${id}`)

// 数据来源
export const CreateDataSource = params => post('/dataMap/dataSource/CreateDataSource', params)
export const UpdateDataSource = params => post('/dataMap/dataSource/UpdateDataSource', params)
export const DeleteDataSourceByIds = params => post('/dataMap/dataSource/DeleteDataSourceByIds', params)
export const GetDataSourceList = params => post('/dataMap/dataSource/GetDataSourceList', params)
// 采集第三方sdk列表
export const CreateAcquisitionThirdSdk = params => post('/dataMap/acquisitionThirdSdk/CreateAcquisitionThirdSdk', params)
export const UpdateAcquisitionThirdSdk = params => post('/dataMap/acquisitionThirdSdk/UpdateAcquisitionThirdSdk', params)
export const DeleteAcquisitionThirdSdkByIds = params => post('/dataMap/acquisitionThirdSdk/DeleteAcquisitionThirdSdkByIds', params)
export const GetAcquisitionThirdSdkList = params => post('/dataMap/acquisitionThirdSdk/GetAcquisitionThirdSdkList', params)

// 资产统计
export const GetSaveCountByMouth = params => post('/dataMap/assetsPrivacyField/GetSaveCountByMouth', params)
export const GetFieldSatisfaction = () => get('/dataMap/assetsPrivacyField/GetFieldSatisfaction')
export const GetSensitiveSatisfaction = () => get('/dataMap/assetsPrivacyField/GetSensitiveSatisfaction')
export const GetClassSatisfaction = () => get('/dataMap/assetsPrivacyField/GetClassSatisfaction')
export const GetAssetsFieldSatisfaction = limit => get(`/dataMap/assetsPrivacyField/GetAssetsFieldSatisfaction?limit =${limit}`)
export const GetAssetsTypeSatisfaction = () => get('/dataMap/assetsPrivacyField/GetAssetsTypeSatisfaction')
export const GetLabelSatisfaction = () => get('/dataMap/assetsPrivacyField/GetLabelSatisfaction')
export const GetPrivateConsanguinityChart = params => post('/privacy/graph/GetPrivateConsanguinityChart', params)

// 导出个人数据字段
export const ExportPrivacyField = () => downloadByGet('/assets/data/export')

export const getAllProduct = () => get('/privacy/graph/GetFilterBusinessList')
export const GetFilterBusinessList = () => get('/privacy/graph/GetFilterBusinessList')
export const GetPrivacyFieldOptionsList = params => post('/privacy/graph/GetFilterFieldList', params)

export const GetMappingWriterData = id => get(`/data/mapping/writer_data?id=${id}`)
export const MappingSaveData = params => post('/data/mapping/activity/save_data', params)
export const CreateDataMapping = params => post('/data/mapping/activity/create', params)
export const GetFilterAssetsList = params => post('/privacy/graph/GetFilterAssetsList', params)
export const GetFilterThirdPartyList = params => post('/privacy/graph/GetFilterThirdPartyList', params)
export const GetFilterFieldList = params => post('/privacy/graph/GetFilterFieldList', params)
export const UpdateDataMappingInfo = params => post('/dataMap/dataMapping/UpdateDataMappingInfo', params)
export const DeleteDataAcquisitionByIds = id => Delete(`/data/mapping/delete?id=${id}`)
export const DeleteDataActivity = id => Delete(`/data/mapping/activity/delete?id=${id}`)
export const GetDataAcquisitionById = id => get(`/data/mapping/activity/info?activityId=${id}`)
export const GetActivityData = (mappingId, activityId) => get(`/data/mapping/activity/question?mappingId=${mappingId}&activityId=${activityId}`)
// export const GetQuestion = (mappingId, activityId) => get(`/data/mapping/activity/question?mappingId=${mappingId}&activityId=${activityId}`)
//
export const saveOrUpdateDataMappingInfo = params => {
  const submitUrl = params.id ? '/data/mapping/activity/update' : '/data/mapping/activity/create'
  return post(submitUrl, params)
}

export const queryActivityInfo = id => get(`/data/mapping/activity/info?activityId=${id}`)
export const selectFiledBySource = (mappingId, sourceId, sourceType, classId) => {
  let url = `/privacy/field/select_by_source?mappingId=${mappingId}&sourceId=${sourceId}&sourceType=${sourceType}`
  if (classId !== undefined) {
    url += `&classId=${classId}`
  }
  return get(url)
}
