<template>
  <div>
    <DatashareStatistics ref="datashareStatistics" />
    <DatashareTable @pageTotalFrensh="handleFilter" />
  </div>
</template>

<script>
import DatashareStatistics from './DatashareStatistics'
import DatashareTable from './DatashareTable'

export default {
  name: 'DataShareList',
  components: { DatashareStatistics, DatashareTable },
  methods: {
    handleFilter() {
      this.$refs.datashareStatistics.getSatisfaction()
    },
  },
}
</script>

<style scoped>

</style>
