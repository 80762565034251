<template>
  <el-dialog
    title="导出"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="80px"
      class="mr10"
    >
      <el-form-item
        label="第三方"
        prop="thirdPartyId"
        :rules="[ { required: true, message: '请选择第三方', trigger: 'change' }]"
      >
        <el-select
          v-model="form.thirdPartyId"
          placeholder="请选择第三方"
          clearable
          @close="getChartData"
        >
          <el-option
            v-for="(item,index) in thirdPartyOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { FilterThirdPartyOptions } from '@/api/enterprise/assest'
import { ShareDataExport } from '@/api/thirdPartCompliance/thirdPartCompliance'
import { GetFilterThirdPartyList } from '@/api/dataMap/dataMapProcess'

export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      thirdPartyOptions: false,
      form: {
        thirdPartyId: '',
      },
    }
  },
  methods: {
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          ShareDataExport(this.form.thirdPartyId)
          this.dialogVisible = false
        }
      })
    },
    getThirdPartyOptions() {
      GetFilterThirdPartyList({ productId: 0 }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.thirdPartyOptions = resData.data
        }
      })
    },
    dialogOpen() {
      this.getThirdPartyOptions()
    },
    dialogClose() {
      this.form = {
        thirdPartyId: '',
      }
    },
  },
}
</script>

<style scoped>

</style>
