<template>
  <b-card>
    <div class="filter-wrap flex-between">
      <div class="flex-start search-filter-wrap">
        <el-input
          v-model="listQuery.SearchStr"
          width="180"
          placeholder="请输入内容"
          clearable
          @clear="handleFilter"
        />
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </div>
      <div class="text-right">
        <el-button
          v-if="exportPerm"
          size="small"
          type="primary"
          icon="el-icon-download"
          @click="exportDialogShow"
        >
          导出
        </el-button>
        <el-button
          v-if="savePerm"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="toSave(1)"
        >
          新增
        </el-button>
      </div>
    </div>
    <div class="table-wrap">
      <FilterSelectList
        :list-query.sync="listQuery"
        :select-list.sync="selectList"
        @handleFilter="handleFilter"
      />
      <el-table
        v-loading="loading"
        fit
        border
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column
          prop="fieldName"
          :show-overflow-tooltip="true"
          label="共享字段"
          :align="'left'"
        >
          <template v-slot="scope">
            <span v-if="scope.row.sensitiveFieldNameList">敏感字段：{{ scope.row.sensitiveFieldNameList }} <br></span>

            <span v-if="scope.row.noSensitiveFieldNameList">非敏感字段：{{ scope.row.noSensitiveFieldNameList }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="thirdPartName"
          :show-overflow-tooltip="true"
          label="第三方名称"
        />
        <el-table-column
          prop="sourceType"
          sortable
          width="140"
          :sort-by="'source_type'"
          :show-overflow-tooltip="true"
          label="数据来源类型"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <TableHeaderFilterDropdown
              :key-str="'sourceType'"
              :select-list.sync="selectList"
              :label="'数据来源类型'"
              :value.sync="listQuery.sourceType"
              :dropdown-options="fromTypeOptions"
              @handleFilter="handleFilter"
            />
          </template>
          <template v-slot="scope">
            {{ formTypeObj[scope.row.sourceType] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sourceName"
          width="120"
          :show-overflow-tooltip="true"
          label="数据来源"
        />
        <el-table-column
          :show-overflow-tooltip="true"
          prop="shareMethod"
          width="120"
          label="共享方式"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <TableHeaderFilterDropdown
              :key-str="'shareMethod'"
              :select-list.sync="selectList"
              :label="'共享方式'"
              :value.sync="listQuery.shareMethod"
              :dropdown-options="shareMethodOptions"
              @handleFilter="handleFilter"
            />
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="sendingLine"
          label="传输方式"
        />
        <el-table-column
          :show-overflow-tooltip="true"
          prop="shareDescription"
          label="共享目的与用途"
        />
        <el-table-column
          :show-overflow-tooltip="true"
          prop="dataSecureWay"
          width="120"
          label="数据保护方式"
        >
          <template v-slot="{row}">
            {{ dataSecureWayObj[row.dataSecureWay] }}
          </template>
        </el-table-column>
        <!--        <el-table-column-->
        <!--          :show-overflow-tooltip="true"-->
        <!--          prop="sendingSecureWay"-->
        <!--          width="120"-->
        <!--          label="传输保护方式"-->
        <!--        >-->
        <!--          <template v-slot="{row}">-->
        <!--            {{ sendingIsEncryptionObj[row.sendingSecureWay] }}-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column
          :show-overflow-tooltip="true"
          prop="createType"
          width="80"
          label="导入方式"
        >
          <template v-slot="{row}">
            {{ createTypeObj[row.createType] }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="80px"
        >
          <template v-slot="{row}">
            <el-link
              v-if="updatePerm"
              class="mr10"
              :underline="false"
              type="primary"
              @click="toSave(2,row.id)"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="delPerm"
              :underline="false"
              type="primary"
              @click="del(row)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <PageQuery
        v-show="total>0"
        :total="total"
        :cache-name="'listQuery15'"
        :update-state="'UPDATE_LISTQUERY15'"
        :list-query.sync="listQuery"
        :init-list-query.sync="initListQuery"
        @pagination="getList"
      />
    </div>
    <DataShareExport ref="dataShareExport" />
  </b-card>
</template>

<script>
import {
  error,
  findBtnPerm,
  success,
  getBtnPerms,
  verifyBtnPerm,
} from '@core/utils/utils'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'
import {
  GetShareDataManageListByPage,
  DeleteShareDataManageByIds, ShareDataExport,
} from '@/api/thirdPartCompliance/thirdPartCompliance'
import DataShareExport from '@/views/thirdPartCompliance/dataShare/DataShareExport.vue'

export default {
  components: {
    FilterSelectList,
    TableHeaderFilterDropdown,
    DataShareExport,
  },
  data() {
    const listQuery = {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      shareMethod: '',
      sourceType: 0,
      order: '',
      orderField: '',
    }
    return {
      shareDictObj: {
        0: '否',
        1: '是',
        2: '不涉及',
      },
      createTypeObj: {
        1: 'dm自动导入',
        2: '手动创建',
      },
      dataSecureWayObj: {
        0: '没有保护措施',
        1: '加密',
        2: '脱敏',
        3: '加密和脱敏',
        4: '其他保护措施',
      },
      sendingIsEncryptionObj: {
        0: '没有保护',
        1: '对称加密传输',
        2: '非对称加密传输',
        3: '其他加密方式',
      },
      formTypeObj: {
        1: '资产',
        2: '业务',
        3: '第三方',
        4: '业务活动',
      },
      statusColorObj: {
        1: 'warning',
        2: 'success',
      },
      shareMethodOptions: [
        {
          label: 'API',
          value: 'API',
        },
        {
          label: '存储介质电子拷贝',
          value: '存储介质电子拷贝',
        },
        {
          label: 'Email',
          value: 'Email',
        },
        {
          label: 'FAX',
          value: 'FAX',
        },
        {
          label: '远程连接',
          value: '远程连接',
        },
        {
          label: '纸件打印',
          value: '纸件打印',
        },
        {
          label: '其他',
          value: '其他',
        },
      ],
      fromTypeOptions: [
        {
          label: '资产',
          value: 1,
        },
        {
          label: '业务',
          value: 2,
        },
        {
          label: '第三方',
          value: 3,
        },
        {
          label: '业务活动',
          value: 4,
        },
      ],
      selectList: [],
      totalShow: true,
      loading: false,
      typeOptions: [],
      lawOptions: [],
      total: 0,
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
      savePerm: findBtnPerm('data-share-2'),
      updatePerm: findBtnPerm('data-share-3'),
      delPerm: findBtnPerm('data-share-4'),
      exportPerm: findBtnPerm('data-share-5'),
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'data-share-2')
      this.updatePerm = verifyBtnPerm(btnPerms, 'data-share-3')
      this.delPerm = verifyBtnPerm(btnPerms, 'data-share-4')
      this.exportPerm = verifyBtnPerm(btnPerms, 'data-share-5')
    })
    const cacheQuery = this.$store.state.pageQuery.listQuery15
    this.listQuery = { ...cacheQuery }
    this.getList()
  },
  methods: {
    exportDialogShow() {
      ShareDataExport(0)
      // this.$refs.dataShareExport.dialogVisible = true
    },
    toSave(type, id) {
      if (type === 1) {
        this.$router.push({ path: '/dataShareSave' })
      } else {
        this.$router.push({
          path: '/dataShareSave',
          query: { id },
        })
      }
    },
    getList() {
      this.loading = true
      // this.listQuery.sourceType = this.listQuery.sourceType ? this.listQuery.sourceType : 0
      GetShareDataManageListByPage(this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        }).catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    del(row) {
      if (row.createType === 1) {
        error(`来源${row.sourceName}个人信息映射，该处不能进行删除，若要删除请在${row.sourceName}个人信息映射中进行删除`)
      } else {
        this.$confirm('是否确认删除吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
        }).then(() => {
          DeleteShareDataManageByIds(row.id)
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.getList()
                this.$emit('handleFilter')
                this.$emit('pageTotalFrensh')
              } else {
                error(resData.msg)
              }
            })
        })
      }
    },
    sortChange(column) {
      const order = column.order ? column.order : 'ascending'
      const orderObj = { descending: 'DESC', ascending: 'ASC' }
      this.listQuery.orderField = column.column.sortBy
      this.listQuery.order = orderObj[order]
      this.getList()
    },
  },
}
</script>

<style scoped>

</style>
