<template>
  <b-card
    no-body
    class="match-height"
  >
    <b-row v-loading="loading">
      <b-col
        md="12"
        xl="12"
      >
        <b-card no-body>
          <b-card-header>
            <el-col
              :span="12"
              class="font-medium-1 mt-50 center"
            >
              <span class="title">统计信息</span>
            </el-col>
          </b-card-header>
          <b-card-body style="margin-left: 10px; margin-right: 0px; margin-top: 15px;">
            <b-row class="align-content-center justify-content-center h-100">
              <b-col
                v-for="(item, index) in categories"
                :key="index"
              >
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar
                      size="32"
                      :variant="backgrounds[index]"
                    >
                      <feather-icon
                        size="16"
                        :icon="index == 0 ? `ArchiveIcon` : `CheckIcon` "
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h5 class="font-weight-bolder mb-0">
                      <span class="info-title">{{ item.value }}</span>
                    </h5>
                    <b-card-text class="mb-0">
                      {{ item.label }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import { GetSatisfaction } from '@/api/thirdPartCompliance/thirdPartCompliance'
import TotalPie from '@/@core/components/total-pie/TotalPie'
import PrecentPie from '@/@core/components/precent-pie/PrecentPie'

export default {
  components: {
    TotalPie,
    PrecentPie,
  },
  data() {
    const backgrounds = ['light-primary', 'light-primary', 'light-info', 'light-warning', 'light-danger', 'light-success']
    return {
      backgrounds,
      barSeriesArr: [],
      categories: [
        { label: '字段数量', value: 0 },
        { label: '第三方数量', value: 0 },
        { label: '加密字段数', value: 0 },
        { label: '脱敏字段数', value: 0 },
      ],
      loading: false,
    }
  },
  created() {
    this.getSatisfaction()
  },
  methods: {
    getSatisfaction() {
      this.loading = true
      GetSatisfaction().then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          this.barSeriesArr = []
          this.categories[0].value = resData.data.total
          this.categories[1].value = resData.data.thirdPartyCount
          this.categories[2].value = resData.data.encryptionCount
          this.categories[3].value = resData.data.desensitizationCount
        }
      }).catch(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

    /*info-title*/
    .info-title{
        width: 60px;
        text-align: center;
    }
</style>
